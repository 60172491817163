@tailwind base;
@tailwind components;
@tailwind utilities;

:root { 
  --gradient: #5271C4;

  --background: 238 31.849999999999998% 4.4%;
  --foreground: 238 4.9% 97.75%;

  --muted: 238 24.5% 16.5%;
  --muted-foreground: 238 4.9% 55.5%;

  --popover: 238 55.2% 7.15%;
  --popover-foreground: 238 4.9% 97.75%;

  --card: 238 55.2% 7.15%;
  --card-foreground: 238 4.9% 97.75%;

  --border: 238 24.5% 16.5%;
  --input: 238 24.5% 16.5%;

  --primary: 238 49% 55%;
  --primary-foreground: 238 4.9% 5.5%;

  --secondary: 238 24.5% 16.5%;
  --secondary-foreground: 238 4.9% 97.75%;

  --accent: 238 24.5% 16.5%;
  --accent-foreground: 238 4.9% 97.75%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 238 4.9% 97.75%;

  --ring: 238 49% 55%;
}
@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground font-['JetBrains_Mono_Variable'];
  }
}

.container {
  @apply w-full max-w-sm mx-auto px-2;
}